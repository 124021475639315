import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import AskView from "./views/AskView";
import ChatView from "./views/ChatView";
import Header from "./ui/Header";
import { searchEmbeddedText } from "./utils/api";
import { isolateText } from "./utils/filter";

function App() {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userMsg, setUserMsg] = useState("");

  const handleSend = async () => {
    if (userMsg) {
      setLoading(true);
      setMessages((prevState) => [
        ...prevState,
        { content: [{ text: { value: userMsg } }], id: uuidv4(), role: "user" },
      ]);
      const textToSearch = isolateText(userMsg);
      setUserMsg("");
      const messages = await searchEmbeddedText(textToSearch);
      console.log(messages)
      setMessages(messages);
      setLoading(false);
    }
  };

  return (
    <div className="bg-[#F4F0F0] w-screen h-screen max-h-screen">
      <Header />
      <main className="h-[46rem] max-h-[80%] w-screen bg-[#F4F0F0]">
        <div className="bg-[#FFF] h-full w-full max-w-4xl mx-auto mt-8 rounded-b-[44px] rounded-t-[60px] flex flex-col justify-between">
          {messages.length === 0 ? (
            <AskView loading={loading} />
          ) : (
            <ChatView handleSend={handleSend} messages={messages} />
          )}
          <div className="m-8 text-[#3C002D] bg-[#F4F0F0] px-2 flex items-center rounded-md">
            <textarea
              value={userMsg}
              type="text"
              className="w-full bg-[#F4F0F0] font-medium font-degular m-4 h-6 rounded-xl resize-none flex items-center justify-center mr-2 focus-visible:outline-none"
              onChange={(event) => setUserMsg(event.target.value)}
            />
            <div
              className={`${userMsg ? "bg-[#3C002D]" : "bg-[#917E8C]"} text-[#FFF] rounded-md w-7 h-7 flex justify-center items-center cursor-pointer`}
            >
              <svg
                viewBox="0 0 12 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-3"
                onClick={() => handleSend()}
              >
                <path
                  d="M1 6L6 1L11 6M6 13V2"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default App;
