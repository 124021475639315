import React from "react";
import OrkesterLogo from "../assets/orkester.svg";
import OpenAILogo from "../assets/openai.svg";

function Header() {
  return (
    <header className="bg-[#FFF] w-full h-20 flex justify-between items-center px-20 text-[#3C002D]">
      <h1 className="text-4xl font-degular">POC n°5 - Embedding</h1>
      <div className="flex items-center">
        <img src={OrkesterLogo} alt="My SVG" />
        <p className="mx-2 font-bold">X</p>
        <img src={OpenAILogo} alt="My SVG" />
      </div>
    </header>
  );
}

export default Header;
