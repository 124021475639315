import axios from "axios";

const url = process.env.REACT_APP_API_URL;
//const url = 'http://localhost:8080'

export const searchEmbeddedText = async (search) => {
  const response = await axios.post(`${url}/embedding/search`, {
    search: search,
  });
  return response.data;
};
