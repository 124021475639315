import React, { Fragment, useRef, useEffect } from "react";
import RightMsg from "../../ui/RightMsg";
import LeftMsg from "../../ui/LeftMsg";

function ChatView({ messages }) {
  const chatRef = useRef(null);

  const goToChatBottom = () => {
    if (chatRef.current) {
      chatRef.current.scrollTop =
        chatRef.current.scrollHeight - chatRef.current.clientHeight;
    }
  };

  useEffect(() => {
    goToChatBottom();
  }, [messages]);

  return (
    <Fragment>
      <div
        className="p-8 pb-0 flex flex-col overflow-scroll border-b-[1px] border-b-[#DDD8D8] h-[85%]"
        ref={chatRef}
        id="no-scrollbar"
      >
        {messages.map((msg) => {
          if (msg.role === "user") {
            return (
              <RightMsg textMsg={msg.content[0].text.value} key={msg.id} />
            );
          } else {
            return (
              <LeftMsg
                textMsg={msg.content[0].text.value}
                key={msg.id}
                flagged={msg.flagged}
                reason={msg.reason}
              />
            );
          }
        })}
      </div>
    </Fragment>
  );
}

export default ChatView;
