import React, { Fragment } from "react";
import Helper from "../../assets/helper.svg";

function AskView({ loading, handleInit }) {
  return (
    <Fragment>
      <div className="h-[85%] border-b-[1px] border-b-[#DDD8D8] flex flex-col justify-center items-center px-8">
        {!loading ? (
          <img
            src={Helper}
            alt="un gentil policier"
            className="mx-auto mb-8 h-36 w-36 rounded-full"
          />
        ) : (
          <div className="relative flex justify-center items-center">
            <div
              class="absolute -top-1 rounded-full animate-spin h-40 w-40"
              id="loading_guy"
            ></div>
            <img
              src={Helper}
              alt="un gentil guide"
              className="mx-auto mb-8 h-36 w-36 rounded-full"
            />
          </div>
        )}
        <h2 className="font-degular text-4xl text-center w-4/5 font-medium text-[#3C002D]">
          Besoin d'aide pour trouver un article de la déclaration des droits de
          l'Homme ?
        </h2>
      </div>
    </Fragment>
  );
}

export default AskView;
